<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="500px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">

      <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
        <funcionario-senha-form v-model="item"/>
      </v-form>

    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
      <v-btn  color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import FuncionarioSenhaForm from "../forms/FuncionarioSenhaForm";
import {updatePeloAdm} from "../../../api/alterarSenha";
import {ApiError} from "../../../api/core";
export default {
  name: "CreateEditSenhaFuncionarioDialog",
  components: {FuncionarioSenhaForm},
  props: ['value','item'],
  data () {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return 'Alterar senha de ' + this.item?.nome;
    }
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          if(this.item.id) {
            await updatePeloAdm(this.item);
          
            this.$emit('input', false);
            this.$emit('onSave');
            this.$toast.success(`Senha alterada com sucesso.`, {
                timeout: 3000
            })
          } else {
            this.$toast.error(`Usuário não encontrado.`, {
            timeout: 6000
          })
          }
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
           timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível alterar a senha do Usuário.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>

</style>