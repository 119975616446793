<template>
  <div>

    <responsive-menu ref="menu"
                     :bind="selectedItem"
                     :title="selectedItemName"
                     :opcoes="menuOptions"
                     @click:item="action => $emit(`click:${action}`, selectedItem)"/>
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  @click:row="onClickRow"
                  @contextmenu:row.prevent="showMenuFromContextMenu"
                  :footer-props="{
      disableItemsPerPage: true,
      itemsPerPageOptions: [itemsPerPage],
    }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>


      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item @click="showMenu($event, item)" v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.nome"/>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAll} from '../../../api/funcionarios';
import ResponsiveMenu from "../../app/ResponsiveMenu";
import {mapGetters} from "vuex";
import {ApiError} from "../../../api/core";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "FuncionariosDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: null,
      menuOptions: [
        {
          action: 'editSenha',
          text: 'Alterar Senha',
          icon: 'mdi-lock',
          permiteAcao: false,
          hiddenWhen(item) {
            return item?.id === item?.idUsuarioLogado;
          },
        },
        {
          action: 'edit',
          text: 'Editar',
          icon: 'mdi-pencil',
          permiteAcao: false,
        },
        {
          action: 'remove',
          text: 'Remover',
          icon: 'mdi-delete',
          color: 'error',
          permiteAcao: false,
          hiddenWhen(item) {
            return item?.id === item?.idUsuarioLogado;
          },
        }
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          value: 'id',
          text: 'ID',
          align: 'start',
          width: 100
        },
        {
          text: 'Nome',
          value: 'nome',
          align: 'start'
        },
        {
          text: 'Login',
          value: 'login',
          align: 'start'
        }, 
        {
          text: 'E-mail',
          value: 'email',
          align: 'start'
        }, 
        {
          text: 'Celular',
          value: 'celular',
          align: 'start'
        },
        {
          text: 'Nível',
          value: 'nivel',
          align: 'start'
        },       
        {
          text: 'Menu',
          width: 30,
          value: 'edit',
          align: 'end'
        }
      ],
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
    selectedItemName() {
      return this.selectedItem?.nome
    },

  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    showMenuFromContextMenu(...contextEvent) {
      const event = contextEvent[0];
      const {item} = contextEvent[1];
      this.showMenu(event, item);
    },
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.selectedItem.nivelUsuarioLogado = this.nivelUsuarioLogado;
      this.selectedItem.idUsuarioLogado = this.user.id;
      console.log(this.selectedItem);
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
        this.elements = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar a lista de Usuários.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loading = false;
      }
    },
    async getPermissoes(){
      this.menuOptions[0].permiteAcao = await this.getPermissaoPorItemPerfil("Funcionario.alterarSenhaPeloAdm",this.nivelUsuarioLogado);
      this.menuOptions[1].permiteAcao = await this.getPermissaoPorItemPerfil("Funcionario.update",this.nivelUsuarioLogado);
      this.menuOptions[2].permiteAcao = await this.getPermissaoPorItemPerfil("Funcionario.exclui",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 128px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>